@import url('https://rsms.me/inter/inter.css');

@font-face {
  font-family: 'ocra', serif;
  src: url('../public/fonts/ocra.ttf') format('ttf');
}

@font-face {
  font-family: 'Conthrax', sans-serif;
  src: url('../public/fonts/conthrax-sb.ttf');
}


* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.main-container {
  position: relative;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.content-about {
  z-index: 1;
}

.starry-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -5; 
}

body {
  background: black;
  color: #f0f0f0;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  overscroll-behavior: none;
}

a {
  color: #f0f0f0;
}

a {
  pointer-events: all;
  color: #f0f0f0;
  text-decoration: none;
}

svg {
  fill: #f0f0f0;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 5s ease 1s forwards;
}

input {
  font-family: 'OCRA', serif;
  width: 100px;
  border: none;
  background-image: none;
  background-color: transparent;
  color: transparent;
  caret-color: #555;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  font-weight: 400;
}

.overlay {
  padding: 40px;
  height: 100%;
  width: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 60px;
}


.LandingName {
  font-family: 'Amatic SC', sans-serif;
  color: #4BD99E;
  font-weight: 700;
  font-size: 4.5em;
  padding: 0;
  margin: 0;
  text-align: center;
}

.LandingSub {
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 2em;
  margin: 0;
  color: #4BD99E;
  text-align: center;
}

input:focus {
  outline: none;
  box-shadow: none !important;
}


.social-btn{
  margin-right: 2rem;
  /* border:1px solid #4BD99E; */
  padding:0.5rem;
}
.neonText {
  font-family: 'Conthrax', sans-serif;
  animation: flicker 1.5s infinite alternate;
  font-weight: 300;
  text-shadow:
          0 0 7px #fff,
          0 0 10px #fff,
          0 0 15px #fff,
          0 0 42px #0fa,
          0 0 62px #0fa,
          0 0 72px #0fa,
          0 0 62px #0fa,
          0 0 11px #0fa;
}

@keyframes flicker {
  0%, 18%, 22%, 25%, 53%, 57%, 100% {
    text-shadow:
            0 0 4px #fff,
            0 0 11px #fff,
            0 0 19px #fff,
            0 0 25px #0fa,
            0 0 38px #0fa,
            0 0 80px #0fa,
            0 0 100px #0fa,
            0 0 150px #0fa;
  }
  20%, 24%, 55% {
    text-shadow: none;
  }
}


.text-date{
  color:#4BD99E;
  font-family: 'Amatic SC';
  font-size: 2rem !important;
  font-weight: 700;
  padding-top: 0px !important;
}


.menu {
  display: flex;
  list-style: none; 
  padding: 0; 
  margin: 0; 
  
}

.menu li {
  margin-right: 20px; 
}

.menu li:last-child {
  margin-right: 0; 
}

.item-link, .anchor-link {
  font-family: "Amatic SC", sans-serif;
  font-size: 2.3rem;
  text-decoration: none;
  color:  #4BD99E; 
  padding: 10px 15px; 
  transition: color 0.3s ease; 
}

.item-link:hover {
  color:  #19777E; 
}


.active{
  color:  #19777E; 
  font-size: 2.7rem;
  font-weight: 700;
}

.hamburger{
  display: none;
}


.info-text{
  color: #4BD99E;
  /* font-family: "Poppins", sans-serif; */
  font-weight: 300;
}


.project-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  width: 75%;
  margin: auto;
  text-align: center;
}

.project-card {
  background-color: #4BD99E;
  padding:0;
  border-radius: 10px;
  box-shadow: 0 4px 6px #19777E;
}

.project-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.project-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 10px;
  color:black;
  padding-left: 10px;
  padding-right: 10px;

}

.project-description {
  font-size: 0.9rem;
  font-family: 'Roboto';
  color: black;
  margin-top: 10px;
  text-align: justify;
  padding-left: 20px;
  padding-right: 20px;
}

.project-link {
  display: inline-block;
  color: #19777E ;
  text-decoration: none;
  font-size: 1.5rem;
  padding-left:0.5rem;
  font-weight: bolder
}

.project-link:hover {
  color:black;
}

.tech-stack {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin: 10px 0;
  
}

.tech-item {
  background:#19777E ;
  color:black;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.875rem;
}


.mobile-link {
  font-family: Roboto, sans-serif;
  font-size: 3em;
  letter-spacing: 0.05em;
  text-decoration: none;
  padding: 1rem 2rem;
  background-image: linear-gradient(90deg, transparent 0%, transparent 50%, #00c174 50%);
  background-size: 240%;
  transition: all 0.4s;
  position: initial;
  margin: 0;
  line-height: 0.9em;
}

@media screen and (max-width: 480px) {
  .mobile-link {
    font-size: 2.2em;
  }
}

.mobile-link:hover,
.mobile-link:active {
  cursor: pointer;
  background-position: 100%;
}




@media only screen and (max-device-width: 480px) {
  .LandingName {
    font-size: 4rem;
    text-align: center;
  }

  .LandingSub {
    padding-top: 1rem;
    font-size: 1.5rem;
    text-align: center;
  }
  .hamburger{
    display: block;
  }

  .menu {
    display: none;
  }
 

}

@media screen and (max-width: 480px) {
  .loader {
    display: none;
  }
}

@media (max-width: 1170px) and (min-width: 767px) {
  .vertical-timeline-element {
    display: block;
    width: 60%;
    
    left: 20% 
  }
  .vertical-timeline::before {
    margin-left: 20% 
  }
  .text-date{
    color:black;
    font-family: 'Amatic SC';
    font-size:1.5rem !important;
    padding-top: 0px !important;
    font-weight: bold !important
  }
}


@media (max-width: 767px) {
  .vertical-timeline-element {
    display: block;
    width: 90%;
    margin: 20px 0; 
  }
  .text-date{
    color:black;
    font-family: 'Amatic SC';
    font-weight: bold !important;
    font-size:1.5rem !important;
    padding-top: 0 !important
  }
}


@media (max-width: 1228px) {
  .project-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 820px) {
  .project-grid {
    grid-template-columns: 1fr;
  }
}

.banner {
  margin-top: 130px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.image-container {
  flex: 0 0 20%;
}

.header-img {
  width: 90%;
  border-radius: 25px;
  box-shadow: 6px 6px 6px #4BD99E;
}

.text-container {
  flex: 0 0 55%;
  padding-left: 3%;
}

.info-text {
  text-align: justify;
}


@media (max-width: 1200px) {
  .content {
    flex-direction: column;
    text-align: center;
  }

  .image-container {
    flex: 0 0 100%;
    margin-bottom: 20px;
  }

  .text-container {
    flex: 0 0 100%;
    padding-left: 0;
    margin-left:11%;
    margin-right: 11%;
  }

  .header-img {
    margin: 0 auto;
    width:50%;
  }
}


.skills {
  text-align: center;
  width: 70%;
  margin: 0 auto;
}

.section-head-text {
  text-align: center;
  margin: auto;
  color: #19777E;
  font-size: 2rem;
  margin-top: 50px;
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  margin-top: 40px;
}

.skill-item {
  border: 2px solid #4BD99E;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
}

.skill-image {
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-bottom: 10px;
}


@media (max-width: 1100px) {
  .skills-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 620px) {
  .skills-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 480px) {
  .skills-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}





